export enum DecorationIdEnum {
  EDIT = 'restaurant-decoration-edit',
  THEME = 'restaurant-decoration-theme',
  UPLOADCOVER = 'restaurant-decoration-upload-cover',
  UPLOADAVATAR = 'restaurant-decoration-upload-avatar',
  ENTERRESTAURANTNAME = 'restaurant-decoration-enter-restaurant-name',
  ENTERDESCRIPTION = 'restaurant-decoration-enter-description',
  NEWTAB = 'restaurant-decoration-create-new-tab',
  CLICKTABNAME = 'restaurant-decoration-click-tab-name',
  DELETETABNAME = 'restaurant-decoration-delete-tab-name',
  DRAGANDROPBLOCK = 'restaurant-decoration-drag-and-drop-block',
  DRAGANDROPBLOCKELEMENT = 'restaurant-decoration-drag-and-drop-block-element',
  UPLOADIMAGE = 'restaurant-decoration-upload-image',
  SAVE = 'restaurant-decoration-save',
  UPLOADBANNER = 'UPLOAD_IMAGE',
  DESCRIPTION = 'DESCRIPTION',
  OPENMENU = 'OPEN_MENU',
  OPENDROPDOWN = 'OPEN_DROPDOWN',
  RESTAURANTNAME = 'RESTAURANTNAME',
  APPLYTHEMEORCANCELPREVIEW = 'APPLYTHEME_OR_CANCELPREVIEW'
}
