<button
  [disabled]="disabled || loading"
  [type]="type || ''"
  [ngClass]="{
    'btn-primary': variant === 'primary',
    'btn-secondary-color': variant === 'info',
    'btn-secondary-gray': variant === 'outline',
    'btn-secondary-primary': variant === 'outline-primary',
    'btn-destructive-primary': variant === 'danger'
  }"
  [class]="'btn btn-md w-max flex items-center gap-1 ' + styleClass"
  (click)="onClick.emit(label)"
>
  <span class="order-2">{{ label }}</span>
  <i
    *ngIf="!!icon"
    [class]="icon"
    [ngClass]="iconPosition === 'right' ? 'order-3' : 'order-1'"
  ></i>
  <i *ngIf="loading" class="pi pi-spin pi-spinner order-4"></i>
</button>
