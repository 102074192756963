import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResponseType } from '../models/interfaces/common';
import { CurrencyType } from '../models/interfaces/billing';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private url = `${environment.BE_URL}billing`;
  constructor(private http: HttpClient) {}

  getAllCurrencies() {
    return this.http.get<ResponseType<CurrencyType[]>>(
      `${this.url}/exchange-rate/currencies`
    );
  }

  getConversionCurrency(currency: string) {
    return this.http.get<ResponseType<number>>(
      `${environment.BE_URL}billing/exchange-rate/USD/${currency}`
    );
  }
}
