import { TabGuidelineEnumId } from 'src/app/features/guideline/enum/tab-id.enum';
import { NavigationItem } from '../models/interfaces/navigation-item';
import { SoctripIcons } from './soctrip-icon.enum';

export const MenuSidebar: NavigationItem[] = [
  {
    path: 'food-management/shop-information',
    title: 'restaurant-info',
    icon: SoctripIcons.INFO_SQUARE,
    id: TabGuidelineEnumId.SHOP_INFO,
  },
  {
    path: 'food-management/food-builder',
    title: 'restaurant-decoration',
    icon: 'sctr-icon-building-02',
    id: TabGuidelineEnumId.RESTAURANT_BUILDER,
  },
  {
    path: 'foods',
    title: 'food-management',
    icon: 'sctr-icon-tag-02',
    id: TabGuidelineEnumId.PRODUCT,
  },
  {
    path: 'food-categories',
    title: 'food-categories',
    icon: 'sctr-icon-building-02',
    id: TabGuidelineEnumId.CATEGORY,
  },
  {
    path: 'reservation',
    title: 'reservation-management',
    icon: 'sctr-icon-file-05',
    id: TabGuidelineEnumId.ORDER,
  },
  {
    title: 'marketing-channel',
    icon: SoctripIcons.STARS_02,
    id: TabGuidelineEnumId.MARKETING,
    children: [
      // {
      //   path: 'vouchers',
      //   title: 'voucher-management',
      //   id: TabGuidelineEnumId.VOUCHER,
      // },
      {
        path: 'commercial-voucher',
        title: 'commercial-voucher',
      },
    ],
  },
  // {
  //   path: 'analytics',
  //   title: 'shop-analytics',
  //   icon: SoctripIcons.BAR_CHART_SQUARE_01,
  //   id: TabGuidelineEnumId.ANALYTICS,
  // },
];
