import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  provideRouter,
  withComponentInputBinding,
} from '@angular/router';
import { AppLayoutComponent } from './shared/layouts/app-layout/app-layout.component';
import { WelcomeComponent } from './shared/components/welcome/welcome.component';

const routes: Routes = [
  { path: 'welcome', component: WelcomeComponent },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'food-management',
        loadChildren: () =>
          import('./features/shop-management/shop-management.module').then(
            (m) => m.ShopManagementModule
          ),
      },
      {
        path: 'food-categories',
        loadChildren: () =>
          import('./features/food-categories/food-categories.module').then(
            (m) => m.FoodCategoriesModule
          ),
      },
      {
        path: 'reservation',
        loadChildren: () =>
          import('./features/reservation/reservation.module').then(
            (m) => m.ReservationModule
          ),
      },
      {
        path: 'foods',
        loadChildren: () =>
          import('./features/foods/foods.module').then((m) => m.FoodModule),
      },
      {
        path: 'vouchers',
        loadChildren: () =>
          import('./features/vouchers/vouchers.module').then(
            (m) => m.VouchersModule
          ),
      },
      {
        path: 'marketing',
        loadChildren: () =>
          import('./features/marketing/marketing.module').then(
            (m) => m.MarketingModule
          ),
      },
      {
        path: 'analytics',
        loadChildren: () =>
          import('./features/analytics/analytics.module').then(
            (m) => m.AnalyticsModule
          ),
      },
      {
        path: 'share-token',
        loadChildren: () =>
          import('./features/share-token/share-token.module').then(
            (m) => m.ShareTokenModule
          ),
      },
      {
        path: 'commercial-voucher',
        loadChildren: () =>
          import(
            './features/commercial-voucher/commercial-voucher.module'
          ).then((m) => m.CommercialVoucherModule),
      },
      {
        path: 'account-balance',
        loadChildren: () =>
          import('./features/account-balance/account-balance.module').then(
            (m) => m.AccountBalanceModule
          ),
      },
      {
        path: '**',
        redirectTo: 'food-management',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [provideRouter(routes, withComponentInputBinding())],
})
export class AppRoutingModule {}
