import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { Router } from '@angular/router';
import shepherd from 'shepherd.js';
import { RestaurantControllerService } from '@soctrip/angular-restaurant-service';
import { BehaviorSubject, of, switchMap } from 'rxjs';

import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { TabStepEnum } from '../enum/tab-step.enum';
import { TabGuidelineEnumId } from '../enum/tab-id.enum';

import { TabPathEnum } from '../enum/tab-path.enum';
import { DecorationIdEnum } from '../enum/decoration-id.enum';

export const stepGuidelineConfig = [
  {
    id: TabGuidelineEnumId.SHOP_INFO,
    step: TabStepEnum.SHOP_INFO,
    path: TabPathEnum.SHOP_INFO,
  },
  {
    id: TabGuidelineEnumId.CATEGORY,
    step: TabStepEnum.CATEGORY,
    path: TabPathEnum.CATEGORY,
  },
  {
    id: TabGuidelineEnumId.PRODUCT,
    step: TabStepEnum.PRODUCT,
    path: TabPathEnum.PRODUCT,
  },
  {
    id: TabGuidelineEnumId.RESTAURANT_BUILDER,
    step: TabStepEnum.RESTAURANT_BUILDER,
    path: TabPathEnum.RESTAURANT_BUILDER,
  },
];

@Injectable({
  providedIn: 'root',
})
export class TabGuidelineService {
  stepSubject = new BehaviorSubject<number>(stepGuidelineConfig.length);
  stepDecorationSubject = new BehaviorSubject("");
  step$ = this.stepSubject.asObservable();
  stepDecorate$ = this.stepDecorationSubject.asObservable();

  step = stepGuidelineConfig.length;

  finishSubject = new BehaviorSubject<boolean>(false);
  finish$ = this.finishSubject.asObservable();
  guidelinePrefix = 'section-guideline.';
  finishInstructions = this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`);
  guidelineRestaurantBuilderPrefix = 'section-guideline.restaurant-builder.';
  nextStep = this.translator.sentenceCase(`${this.guidelinePrefix}next`);
  backStep = this.translator.sentenceCase(`${this.guidelinePrefix}back`);

  constructor(
    private guideTour: ShepherdService,
    private router: Router,
    private translator: CustomTranslateService,
    private shopService: RestaurantControllerService,
  ) { }

  getStep() {
    return this.step;
  }

  setStep(step: number) {
    this.step = step;
  }

  isActiveStep(id: TabGuidelineEnumId) {
    const index = stepGuidelineConfig.findIndex((step) => step.id == id);
    if (index == -1 && this.getStep() < stepGuidelineConfig.length) {
      return true;
    } else {
      return this.getStep() < index;
    }
  }

  isCurStep(id: TabGuidelineEnumId) {
    const index = stepGuidelineConfig.findIndex((step) => step.id == id);
    return this.getStep() == index;
  }

  onActionStep(path: TabPathEnum) {
    this.router.navigateByUrl(path);
    this.guideTour.hide();
  }

  onSkipStep() {
    this.shopService.restaurantsStagePut().subscribe({
      next: (res) => {
        this.stepSubject.next(res?.data?.stage ?? stepGuidelineConfig.length);
        this.guideTour.next();
      },
    });
  }

  onFinishStep(step: TabStepEnum) {
    const index = stepGuidelineConfig.findIndex((tab) => tab.step === step);
    const nextStep = stepGuidelineConfig[index + 1];

    if (this.getStep() <= index) {
      this.shopService.restaurantsStagePut(index + 1).subscribe({
        next: (res) => {
          if (res?.data?.stage) {
            this.stepSubject.next(res?.data?.stage ?? 0);
            if (nextStep?.id) {
              this.guideTour.show(nextStep?.id);
            }
          }
        },
      });
    }
  }

  //SHOP DECORATION
  onDecorateRestaurant() {
    this.router.navigate(['food-management', 'food-builder']);
    setTimeout(() => {
      this.guideTour.hide();
      this.guideTour.modal = true;
      this.guideTour.confirmCancel = false;
      this.guideTour.addSteps([
        {
          id: DecorationIdEnum.THEME,
          attachTo: {
            element: `#${DecorationIdEnum.THEME}`,
            on: 'right',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}theme-library`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}theme-description`),
          classes: 'mt-4',
        },
        {
          id: DecorationIdEnum.APPLYTHEMEORCANCELPREVIEW,
          attachTo: {
            element: `#${DecorationIdEnum.APPLYTHEMEORCANCELPREVIEW}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(
            `${this.guidelineRestaurantBuilderPrefix}apply-or-cancelPreview-for-theme`,
          ),
          text: this.translator.sentenceCase(
            `${this.guidelineRestaurantBuilderPrefix}apply-or-cancelPreview-for-description`,
          ),
          classes: 'mt-4',
        },
      ] as unknown as shepherd.Step.StepOptions[]);
      this.guideTour.start();
    }, 200);
  }

  onDecorateRestaurantCancelPreview() {
    setTimeout(() => {
      this.guideTour.hide();
      this.guideTour.modal = true;
      this.guideTour.confirmCancel = false;
      this.guideTour.addSteps([
        {
          id: DecorationIdEnum.EDIT,
          attachTo: {
            element: `#${DecorationIdEnum.EDIT}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}edit-restaurant`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}edit`),
          classes: 'mt-4',
        },
        {
          id: DecorationIdEnum.UPLOADCOVER,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADCOVER}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}next`),
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-cover-title`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-cover`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.UPLOADAVATAR,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADAVATAR}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}back`),
              type: 'back'
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}next`),
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-avatar-title`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-avatar`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.NEWTAB,
          attachTo: {
            element: `#${DecorationIdEnum.NEWTAB}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}back`),
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}choose-dropdown`),
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.OPENDROPDOWN);
                this.guideTour.hide();
              }
            }
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}create-new-tab-title`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}create-new-tab`),
          classes: 'mt-4',
        },
        {
          id: DecorationIdEnum.CLICKTABNAME,
          attachTo: {
            element: `#${DecorationIdEnum.CLICKTABNAME}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}next`),
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}new-tab-name`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}click-tab-name`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.DRAGANDROPBLOCK,
          attachTo: {
            element: `#${DecorationIdEnum.DRAGANDROPBLOCK}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}back`),
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}next`),
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}position-drag-drop`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}drag-and-drop-block`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.DRAGANDROPBLOCKELEMENT,
          attachTo: {
            element: `#${DecorationIdEnum.DRAGANDROPBLOCKELEMENT}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}back`),
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}drag-and-drop`),
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.OPENMENU);
                this.guideTour.hide();
              }
            }
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}decorative-block`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}drag-and-drop-block-element`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.UPLOADIMAGE,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADIMAGE}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}back`),
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}next`),
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.UPLOADBANNER);
                this.guideTour.next();
              }
            }
          ],
          scrollTo: true,
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-image-title`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-image`),
          classes: 'mr-16',
        },
        {
          id: DecorationIdEnum.SAVE,
          attachTo: {
            element: `#${DecorationIdEnum.SAVE}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}save-restaurant`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}save`),
          classes: 'mt-4',
        },
      ] as unknown as shepherd.Step.StepOptions[]);
      this.guideTour.start();
    }, 0);
  }

  onDecorateRestaurantApply() {
    setTimeout(() => {
      this.guideTour.hide();
      this.guideTour.modal = true;
      this.guideTour.confirmCancel = false;
      this.guideTour.addSteps([
        {
          id: DecorationIdEnum.UPLOADCOVER,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADCOVER}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}next`),
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-cover-title`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-cover`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.UPLOADAVATAR,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADAVATAR}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}back`),
              type: 'back'
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}next`),
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-avatar-title`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-avatar`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.NEWTAB,
          attachTo: {
            element: `#${DecorationIdEnum.NEWTAB}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}back`),
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}choose-dropdown`),
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.OPENDROPDOWN);
                this.guideTour.hide();
              }
            }
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}create-new-tab-title`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}create-new-tab`),
          classes: 'mt-4',
        },
        {
          id: DecorationIdEnum.CLICKTABNAME,
          attachTo: {
            element: `#${DecorationIdEnum.CLICKTABNAME}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}next`),
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}new-tab-name`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}click-tab-name`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.DRAGANDROPBLOCK,
          attachTo: {
            element: `#${DecorationIdEnum.DRAGANDROPBLOCK}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}back`),
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}next`),
              type: 'next',
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}position-drag-drop`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}drag-and-drop-block`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.DRAGANDROPBLOCKELEMENT,
          attachTo: {
            element: `#${DecorationIdEnum.DRAGANDROPBLOCKELEMENT}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}back`),
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}drag-and-drop`),
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.OPENMENU);
                this.guideTour.hide();
              }
            }
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}decorative-block`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}drag-and-drop-block-element`),
          classes: 'mr-4',
        },
        {
          id: DecorationIdEnum.UPLOADIMAGE,
          attachTo: {
            element: `#${DecorationIdEnum.UPLOADIMAGE}`,
            on: 'left',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}back`),
              type: 'back',
            },
            {
              classes: 'shepherd-button-primary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}next`),
              action: () => {
                this.stepDecorationSubject.next(DecorationIdEnum.UPLOADBANNER);
                this.guideTour.next();
              }
            }
          ],
          scrollTo: true,
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-image-title`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}upload-image`),
          classes: 'mr-16',
        },
        {
          id: DecorationIdEnum.SAVE,
          attachTo: {
            element: `#${DecorationIdEnum.SAVE}`,
            on: 'bottom',
          },
          buttons: [
            {
              classes: 'shepherd-button-secondary',
              text: this.translator.sentenceCase(`${this.guidelinePrefix}skip-instructions`),
              action: () => {
                this.onFinishGuideline();
              },
            },
          ],
          highlightClass: 'highlight',
          title: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}save-restaurant`),
          text: this.translator.sentenceCase(`${this.guidelineRestaurantBuilderPrefix}save`),
          classes: 'mt-4',
        },
      ] as unknown as shepherd.Step.StepOptions[]);
      this.guideTour.start();
    }, 0);
  }

  onDecorateFinish(isShowToast: boolean) {
    this.shopService
      .restaurantsStatusGet()
      .pipe(
        switchMap((res) => {
          if (
            res?.data?.stage &&
            res?.data?.stage < stepGuidelineConfig.length
          ) {
            return this.shopService.restaurantsStagePut(stepGuidelineConfig.length);
          } else {
            return of(undefined);
          }
        }),
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.stage) {
            this.stepSubject.next(res?.data?.stage ?? 0);
            if (isShowToast) {
              this.finishSubject.next(true);
            }
            this.guideTour.complete();
          }
        },
      });
  }

  onFinishGuideline() {
    this.shopService
      .restaurantsStatusGet()
      .pipe(
        switchMap((res) => {
          if (
            res?.data?.stage &&
            res?.data?.stage < stepGuidelineConfig.length
          ) {
            return this.shopService.restaurantsStagePut(stepGuidelineConfig.length);
          } else {
            return of(undefined);
          }
        }),
      )
      .subscribe({
        next: (res) => {
          if (res?.data?.stage) {
            this.stepSubject.next(res?.data?.stage ?? 0);
            this.finishSubject.next(true);
            this.guideTour.complete();
          }
        },
      });
  }

  onInit(curIndex: number, code:string) {
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;

    const infoShopStep = {
      id: TabGuidelineEnumId.SHOP_INFO,
      step: TabStepEnum.SHOP_INFO,
      attachTo: {
        element: `#${TabGuidelineEnumId.SHOP_INFO}`,
        on: 'bottom',
      },
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: this.translator.sentenceCase(
            this.guidelinePrefix + 'skip-instructions',
          ),
          action: () => {
            this.onFinishGuideline();
          },
        },
        {
          classes: 'shepherd-button-outline',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'skip'),
          action: () => {
            this.onSkipStep();
          },
        },
        {
          classes: 'shepherd-button-primary',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'enter'),
          action: () => {
            this.onActionStep(TabPathEnum.SHOP_INFO);
          },
        },
      ],
      highlightClass: 'highlight',
      title: this.translator.sentenceCase(
        this.guidelinePrefix + 'shop-information',
      ),
      text: this.translator.sentenceCase(
        this.guidelinePrefix + 'content-shop-information',
      ),
      classes: 'mt-4',
    };
    const categoryStep = {
      id: TabGuidelineEnumId.CATEGORY,
      step: TabStepEnum.CATEGORY,
      attachTo: {
        element: `#${TabGuidelineEnumId.CATEGORY}`,
        on: 'right',
      },
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: this.translator.sentenceCase(
            this.guidelinePrefix + 'skip-instructions',
          ),
          action: () => {
            this.onFinishGuideline();
          },
        },
        {
          classes: 'shepherd-button-outline',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'skip'),
          action: () => {
            this.onSkipStep();
          },
        },
        {
          classes: 'shepherd-button-primary',
          text: this.translator.sentenceCase(
            this.guidelinePrefix + 'create-category',
          ),
          action: () => {
            this.onActionStep(TabPathEnum.CATEGORY);
          },
        },
      ],
      highlightClass: 'highlight',
      title: this.translator.sentenceCase(
        this.guidelinePrefix + 'create-category',
      ),
      text: this.translator.sentenceCase(
        this.guidelinePrefix + 'content-create-category',
      ),
      classes: 'ml-4',
    };
    const productStep = {
      id: TabGuidelineEnumId.PRODUCT,
      step: TabStepEnum.PRODUCT,
      attachTo: {
        element: `#${TabGuidelineEnumId.PRODUCT}`,
        on: 'right',
      },
      buttons: [
        {
          classes: 'shepherd-button-secondary',
          text: this.translator.sentenceCase(
            this.guidelinePrefix + 'skip-instructions',
          ),
          action: () => {
            this.onFinishGuideline();
          },
        },
        {
          classes: 'shepherd-button-outline',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'skip'),
          action: () => {
            this.onSkipStep();
          },
        },
        {
          classes: 'shepherd-button-primary',
          text: this.translator.sentenceCase(
            this.guidelinePrefix + 'create-product',
          ),
          action: () => {
            this.onActionStep(TabPathEnum.PRODUCT);
          },
        },
      ],
      highlightClass: 'highlight',
      title: this.translator.sentenceCase(
        this.guidelinePrefix + 'create-product',
      ),
      text: this.translator.sentenceCase(
        this.guidelinePrefix + 'content-create-product',
      ),
      classes: 'ml-4',
    };
    const shopBuilderStep = {
      id: TabGuidelineEnumId.RESTAURANT_BUILDER,
      step: TabStepEnum.RESTAURANT_BUILDER,
      attachTo: {
        element: `#${TabGuidelineEnumId.RESTAURANT_BUILDER}`,
        on: 'bottom',
      },
      buttons: [
        {
          classes: 'shepherd-button-primary',
          text: this.translator.sentenceCase(this.guidelinePrefix + 'finish'),
          action: () => {
            this.onFinishGuideline();
          },
        },
        {
          classes: 'shepherd-button-primary',
          text: this.translator.sentenceCase(
            this.guidelinePrefix + 'decoration',
          ),
          action: () => {
            this.onDecorateRestaurant();
          },
        },
      ],
      highlightClass: 'highlight',
      title: this.translator.sentenceCase(this.guidelinePrefix + 'decoration'),
      text: this.translator.sentenceCase(
        this.guidelinePrefix + 'content-decoration',
      ),
      classes: 'mt-4 ml-2',
    };

    if(code === 'NEW'){
      infoShopStep.buttons.pop()
    }

    const steps = [infoShopStep, categoryStep, productStep, shopBuilderStep];

    const stepConfig: shepherd.Step.StepOptions[] = [];

    stepGuidelineConfig.forEach((order) => {
      const step = steps.find((item) => item.id == order.id);
      if (step) {
        stepConfig.push(step as shepherd.Step.StepOptions);
      }
    });

    this.guideTour.addSteps(stepConfig.slice(curIndex));
    this.guideTour.start();
  }
}
