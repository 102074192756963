import { IService } from '../interface/IEnvironment.interface';

export const PREFIX = {
  API_V1: 'api/v1',
  AVATAR_DIR: 'sso-service/static/images/avatars',
  DEV: 'dev',
  PROD: 'production',
  QA: 'qa',
  LOCAL: 'local',
};

export const SERVICES: IService = {
  STOCK: 'stock',
  CATALOG: 'catalog',
  ANGULAR_CATALOG: 'catalog',
  SHOP: 'shop',
  STORAGE: 'storage',
  FOOD: 'food',
  RESTAURANT: 'restaurant',
  PROMOTION: 'promotion',
  PAYMENT: 'payment',
  ORDER: 'food-order',
  SHIPMENT: 'shipment',
  STORAGE_STREAMING: 'storage-streaming',
  E_COMMERCE_WALLET: 'ecommerce-wallet',
  E_COMMERCE_FINANCE: 'ecommerce-finance',
};
