export enum DataShowFormat {
  PRIMENG_ISO_DATE = 'yy/mm/dd',
  PRIMENG_ISO_DATETIME = 'yy/mm/dd HH:mm',
  PRIMENG_ISO_DATETIME_SEC = 'yy/mm/dd HH:mm:ss',
  PRIMENG_EN_DATE = 'yy/mm/dd',
  PRIMENG_EN_DATETIME = 'yy/mm/dd HH:mm',
  PRIMENG_EN_DATETIME_SEC = 'yy/mm/dd HH:mm:ss',
  PRIMENG_VI_DATE = 'dd/mm/yy',
  PRIMENG_VI_DATETIME = 'dd/mm/yy HH:mm',
  PRIMENG_VI_DATETIME_SEC = 'dd/mm/yy HH:mm:ss',

  ISO_DATE = 'yyyy/MM/dd',
  ISO_DATETIME = 'yyyy/MM/dd HH:mm',
  ISO_DATETIME_SEC = 'yyyy/MM/dd HH:mm:ss',
  EN_DATE = 'yyyy/MM/dd',
  EN_DATETIME = 'yyyy/MM/dd HH:mm',
  EN_DATETIME_SEC = 'yyyy/MM/dd HH:mm:ss',
  VI_DATE = 'dd/MM/yyyy',
  VI_DATETIME = 'dd/MM/yyyy HH:mm',
  VI_DATETIME_SEC = 'dd/MM/yyyy HH:mm:ss',
}
