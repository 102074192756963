export enum TabGuidelineEnumId {
  SHOP_INFO = 'tab-info-guideline',
  CATEGORY = 'tab-category-guideline',
  PRODUCT = 'tab-product-guideline',
  RESTAURANT_BUILDER = 'tab-restaurant_builder-guideline',
  ORDER = 'tab-order-guideline',
  VOUCHER = 'tab-voucher-guideline',
  MARKETING = 'tab-marketing-guideline',
  ANALYTICS = 'tab-analytics-guideline'
}
