<div
  [ngClass]="isMenuShow ? 'w-[16.5rem]' : 'w-[4.25rem]'"
  class="max-lg:hidden relative min-h-[calc(100vh-3.125rem)] h-full grid gap-2 content-start px-2 py-3 border-r bg-white transition-all"
>
  <ng-template [ngTemplateOutlet]="navigateSection"></ng-template>
  <i
    (click)="isMenuShow = !isMenuShow"
    class="{{ getMenuIcon() }}
      absolute z-10 top-4 right-0 translate-x-1/2 translate-y-full p-2 text-gray-400 bg-white rounded-full shadow cursor-pointer"
    style="font-size: 0.75rem"
  ></i>
</div>

<p-sidebar [(visible)]="sidebarVisible" (visibleChange)="updateSideBar()">
  <ng-template [ngTemplateOutlet]="navigateSection"></ng-template>
</p-sidebar>

<ng-template #navigateSection>
  <!-- PARENT -->
  <div *ngFor="let item of MenuSidebar; let index = index" [id]="item.id">
    <div
      (click)="onClickItemSidebar(item, index)"
      class="relative flex items-center justify-between rounded-sm py-2 pl-4 pr-3 min-w-max select-none transition font-medium"
      [ngClass]="[
        item.path && isActive(item.path, !!item.children)
          ? 'text-primary-700 font-semibold bg-primary-100 after:absolute after:left-0 after:h-5 after:w-1 after:rounded-r after:bg-primary-600'
          : '',
        isDisableLink(item.id)
          ? 'opacity-50 cursor-not-allowed'
          : 'hover:bg-gray-100 cursor-pointer'
      ]"
      [title]="'section-sidebar.' + item.title | translate | sentenceCase"
    >
      <div class="flex items-center gap-3">
        <i
          [ngClass]="
            item.path && isActive(item.path, !!item.children)
              ? 'before:!text-primary-700 font-semibold'
              : ''
          "
          class="{{ item.icon }} text-xl"
        ></i>
        <span *ngIf="isMenuShow || sidebarVisible" class="text-sm">
          {{
            "section-sidebar." + item.title | translate | capitalizeFirstCase
          }}
        </span>
      </div>
      <span
        *ngIf="!!item.children && (isMenuShow || sidebarVisible)"
        [ngClass]="showChildren[index] ? 'rotate-180' : ''"
        class="pi !text-gray-500 !text-sm pi-chevron-up transition"
      ></span>
    </div>
    <!-- CHILDREN -->
    <ul
      *ngIf="
        item.children && showChildren[index] && (isMenuShow || sidebarVisible)
      "
    >
      <ng-container *ngFor="let child of item.children">
        <li
          *ngIf="!!child.path"
          [ngClass]="[
            isActive(item.path ? [item.path, child.path].join('/') : child.path)
              ? 'text-primary-700 font-semibold bg-primary-100 '
              : '',
            isDisableLink(item.id)
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-gray-100 cursor-pointer'
          ]"
          (click)="onNavigate(item.id, [item.path, child.path].join('/'))"
          class="mt-1 h-9 flex items-center rounded transition"
          [title]="
            'section-sidebar.' + child.title | translate | capitalizeFirstCase
          "
        >
          <div class="flex items-center h-full">
            <div class="w-11 flex items-center justify-center">
              <span
                [ngClass]="
                  isActive(
                    item.path ? [item.path, child.path].join('/') : child.path
                  )
                    ? 'bg-primary-600'
                    : 'bg-gray-300'
                "
                class="h-1 w-1 rounded-full"
              ></span>
            </div>
            <span class="text-sm">
              {{
                "section-sidebar." + child.title
                  | translate
                  | capitalizeFirstCase
              }}
            </span>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
