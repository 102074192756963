<div
  *ngIf="stateView === 'APP'"
  class="body-wrapper max-w-[1920px] mx-auto h-screen flex flex-col bg-gray-50 overflow-auto"
>
  <!-- Virtual header has the same height with <header>, to support UI flows -->
  <header
    class="min-h-[3.125rem] max-h-[3.125rem] w-full bg-transparent border-transparent select-none"
  ></header>
  <!-- Virtual header -->

  <!-- HEADER, with proper z-index for another elements to pop up: dialog, tooltip, ... -->
  <app-header
    class="fixed z-20 w-full h-[3.125rem] max-w-[1920px] min-w-[480px] bg-white shadow-sm"
    [isSmallScreen]="isSmallScreen"
    [user]="user"
    (openSidebar)="sidebarVisible = $event"
  ></app-header>

  <!-- Body -->
  <div class="w-full h-auto min-w-[480px] flex flex-grow mx-auto">
    <!-- SIDEBAR -->
    <app-sidebar [(sidebarVisible)]="sidebarVisible"></app-sidebar>

    <!-- CONTENT -->
    <div id="content" class="w-full h-full p-6">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- End body -->
</div>

<a
  *ngIf="env.state !== 'DEVELOPMENT' && env.state !== 'LOCAL'"
  href="https://forms.gle/eX9Jb2eDMPuw6Hky9"
  target="_blank"
  class="fixed bottom-20 right-10 flex items-center gap-2 px-1 py-1 rounded-full shadow-lg border border-gray-100 cursor-pointer bg-white hover:bg-gray-25 group"
>
  <span class="text-xs font-semibold group-hover:ml-2 hidden group-hover:block"
    >Give feedback</span
  >
  <img
    class="h-10 w-10"
    src="/assets/imgs/logo/{{ env.APP_LOGO }}.svg"
    alt="Logo App"
  />
</a>

<app-toast-message></app-toast-message>

<iframe
  style="display: none"
  id="socialLogin"
  [src]="iframeUrl"
  title="socialLogin"
  (load)="handleOnLoad()"
></iframe>
