import { Component, Input, OnChanges } from '@angular/core';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-custom-image',
  templateUrl: './custom-image.component.html',
  styleUrls: ['./custom-image.component.scss'],
})
export class CustomImageComponent implements OnChanges {
  @Input({ required: true }) src = '';
  @Input({ required: true }) alt = '';

  @Input() class = '';
  @Input() imageClass = '';
  imageLoader = true;

  constructor(public fileService: FileService) {}

  ngOnChanges() {
    if (this.fileService.isYoutubeUrl(this.src)) {
      const id = this.fileService.getYoutubeId(this.src);
      this.src = this.fileService.getThumbnailYoutube(id);
    } else if (this.fileService.isVideoUrl(this.src)) {
      this.src = this.fileService.convertWebmToWebp(this.src);
    } else {
      this.src = this.fileService.getThumbnailWebp(this.src);
    }
  }

  onImageError() {
    this.imageLoader = false;
    this.src = 'assets/imgs/default/image.webp';
  }

  onImageLoad() {
    this.imageLoader = false;
  }
}
