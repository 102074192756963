import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResponseType } from '../models/interfaces/common';
import { Attachment } from '../models/interfaces/attachment';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private api: string = `${
    environment.BE_URL + environment.SERVICES?.['STORAGE']
  }`;
  private apiStreaming: string = `${environment.BE_URL}${environment.SERVICES?.['STORAGE_STREAMING']}`;
  constructor(private http: HttpClient) {}

  getApi() {
    return this.api;
  }

  uploadFile(file: File, description?: string) {
    const resource = '/files/upload-file';
    const formData = new FormData();
    formData.append('file', file);
    if (description) {
      formData.append('description', description);
    }
    return this.http.post(this.api + resource, formData);
  }

  uploadFileList(files: Array<Blob>, description?: string) {
    const resource = '/files/upload-files';
    const formData = new FormData();
    files.forEach((f: any) => {
      formData.append('files', f);
    });
    if (description) {
      formData.append('description', description);
    }
    return this.http.post<ResponseType<Attachment[]>>(
      this.api + resource,
      formData
    );
  }

  getWebFile(id: string) {
    const resource = '/files/web/' + id;
    return this.http.get(this.api + resource, { responseType: 'blob' });
  }

  getImgWebp(id: string) {
    if (!id) return this.getDefaultImg();
    id = id.endsWith('webp') ? id.slice(0, -4) : id;
    return `${this.api}/files/web/${id}.webp`;
  }

  getImgWebpUndefined(id: string) {
    if (!id) return undefined;
    id = id.endsWith('webp') ? id.slice(0, -4) : id;
    return `${this.api}/files/web/${id}.webp`;
  }

  getThumbnailWebp(id: string) {
    if (!id) return this.getDefaultImg();
    id = id.endsWith('webp') ? id.slice(0, -4) : id;
    return `${this.api}/files/thumbnail/${id}.webp`;
  }

  getMobileWebp(id: string) {
    if (!id) return this.getDefaultImg();
    id = id.endsWith('webp') ? id.slice(0, -4) : id;
    return `${this.api}/files/mobile/${id}.webp`;
  }

  getImgPng(id: string) {
    let url = environment.BE_URL;
    url = url.endsWith('/') ? url.slice(0, -1) : url;
    id = id?.startsWith('/') ? id?.slice(1) : id;
    return id ? `${url}/${id}` : this.getDefaultImg();
  }

  getAvatarPng(id: string) {
    let url = environment.BE_URL;
    url = url.endsWith('/') ? url.slice(0, -1) : url;
    id = id?.startsWith('/') ? id?.slice(1) : id;
    return id ? `${url}/${id}` : this.getAvatarDefault();
  }

  getDefaultImg() {
    return 'assets/imgs/default/image.webp';
  }

  getAvatarDefault() {
    return 'assets/imgs/default/avatar.webp';
  }

  getVideoSrc(id: string) {
    return `${this.api}/files/stream-video/${id}.webm?quality=360`;
  }

  getYoutubeId = (url: string) => {
    const result = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return result[2] !== undefined
      ? result[2].split(/[^0-9a-z_\-]/i)[0]
      : result[0];
  };

  getThumbnailYoutube(link: string) {
    const id = this.getYoutubeId(link);
    return `https://img.youtube.com/vi/${id}/mqdefault.jpg`;
  }

  isYoutubeUrl(url: string) {
    if (!url) return false;
    const p =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return !!url?.match(p);
  }

  isVideoUrl(url: string) {
    const videoUri = `${environment.BE_URL}${environment.SERVICES?.['STORAGE']}/files/stream-video`;
    return url?.indexOf(videoUri) > -1;
  }

  convertWebmToWebp(webmUrl: string): string {
    const convertedUrl = webmUrl
      .replace(/\/stream-video\//, '/thumbnail/')
      .replace(/\/storage\//, '/storage-streaming/')
      .replace('.webm', '.webp');

    return convertedUrl;
  }

  getThumbnailVideo(id?: string) {
    if (!id) return this.getDefaultImg();
    return `${this.apiStreaming}/files/thumbnail/${id}.webp`;
  }
}
