<router-outlet></router-outlet>

<a
  *ngIf="env.state !== 'DEVELOPMENT' && env.state !== 'LOCAL'"
  href="https://forms.gle/eX9Jb2eDMPuw6Hky9"
  target="_blank"
  class="fixed bottom-20 right-10 flex items-center gap-2 px-1 py-1 rounded-full shadow-lg border border-gray-100 cursor-pointer bg-white hover:bg-gray-25 group"
>
  <span class="text-xs font-semibold group-hover:ml-2 hidden group-hover:block"
    >Give feedback</span
  >
  <img
    class="h-10 w-10"
    src="/assets/imgs/logo/{{ env.APP_LOGO }}.svg"
    alt="Logo Soctrip"
  />
</a>
