import { IEnvironment } from './interface/IEnvironment.interface';
import { PREFIX, SERVICES } from './path-consts/prefix.env';

export const environment: IEnvironment = {
  state: 'DEVELOPMENT',
  FE_URL: `https://${PREFIX.DEV}-statistics.tma-swerp.com/`,
  BE_URL: `https://api-${PREFIX.DEV}-tmtrav.tma-swerp.com/`,
  API_URL: `https://api-${PREFIX.DEV}-tmtrav.tma-swerp.com`,
  IMAGE_URL: 'https://api-dev.tma-swerp.com/ecommerce-management',
  FE_SSO_URL: 'https://dev-sso.tma-swerp.com/',
  BE_SSO_URL: 'https://api-dev.tma-swerp.com/sso-service/',
  IFRAME_CONNECT_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com/app-connect`,
  FOOD_URL: `https://tmtrav-food-${PREFIX.DEV}.tma-swerp.com/food`,
  APP_NAME: 'TMTravel',
  APP_LOGO: 'tmtravel-logo',
  LOGIN_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com`,
  MODULE: 'RESTAURANT',
  SERVICES: SERVICES,
};
