import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { DropdownModule } from 'primeng/dropdown';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import {
  ApiModule as FoodApiModule,
  BASE_PATH as FOOD_PATH,
} from '@soctrip/angular-food-service';

import {
  ApiModule as CataloApiModule,
  BASE_PATH as CATALOG_PATH,
} from '@soctrip/angular-catalog-service';
import { environment } from 'src/environments/environment';
import {
  ApiModule as RestaurantApiModule,
  BASE_PATH as RESTAURANT_PATH,
} from '@soctrip/angular-restaurant-service';
import {
  ApiModule as PromotionApiModule,
  BASE_PATH as PROMOTION_PATH,
} from '@soctrip/angular-promotion-service';
import {
  ApiModule as PaymentApiModule,
  BASE_PATH as PAYMENT_PATH,
} from '@soctrip/angular-payment-service';
import {
  ApiModule as OrderApiModule,
  BASE_PATH as ORDER_PATH,
} from '@soctrip/angular-food-order-service';
import {
  ApiModule as ShipmenApiModule,
  BASE_PATH as SHIPMENT_PATH,
} from '@soctrip/angular-shipment-service';
import {
  ApiModule as ECommerceWalletApiModule,
  BASE_PATH as E_COMMERCE_WALLET_PATH,
} from '@soctrip/angular-ecommerce-wallet-service';
import {
  ApiModule as ECommerceFinanceApiModule,
  BASE_PATH as E_COMMERCE_FINANCE_PATH,
} from '@soctrip/angular-ecommerce-finance-service';
import { Locales } from './core/constants/locales.config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DropdownModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FoodApiModule,
    RestaurantApiModule,
    CataloApiModule,
    PaymentApiModule,
    PromotionApiModule,
    OrderApiModule,
    ShipmenApiModule,
    ECommerceWalletApiModule,
    ECommerceFinanceApiModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: FOOD_PATH,
      useValue: `${environment.BE_URL + environment.SERVICES?.['FOOD']}`,
    },
    {
      provide: RESTAURANT_PATH,
      useValue: `${environment.BE_URL + environment.SERVICES?.['RESTAURANT']}`,
    },
    {
      provide: CATALOG_PATH,
      useValue: `${environment.BE_URL + environment.SERVICES?.['CATALOG']}`,
    },
    {
      provide: PAYMENT_PATH,
      useValue: `${environment.BE_URL + environment.SERVICES?.['PAYMENT']}`,
    },
    {
      provide: PROMOTION_PATH,
      useValue: `${environment.BE_URL + environment.SERVICES?.['PROMOTION']}`,
    },
    {
      provide: ORDER_PATH,
      useValue: `${environment.BE_URL + environment.SERVICES?.['ORDER']}`,
    },
    {
      provide: SHIPMENT_PATH,
      useValue: `${environment.BE_URL + environment.SERVICES?.['SHIPMENT']}`,
    },
    {
      provide: E_COMMERCE_WALLET_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.['E_COMMERCE_WALLET']
      }`,
    },
    {
      provide: E_COMMERCE_FINANCE_PATH,
      useValue: `${
        environment.BE_URL + environment.SERVICES?.['E_COMMERCE_FINANCE']
      }`,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    this.initI18n();
  }
  private initI18n() {
    this.translate.addLangs(Locales);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
