import { Component, inject } from '@angular/core';
import localeVi from '@angular/common/locales/vi';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { Toast } from 'src/app/core/utils/toast.util';
import { GuidelineService } from 'src/app/features/guideline/services/guideline.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { User } from '../../components/header/header.component';
import { Observable, catchError, forkJoin, of, switchMap } from 'rxjs';
import { BillingService } from 'src/app/core/services/billing.service';
import { registerLocaleData } from '@angular/common';

export const STATE_RENDER_APP = {
  INIT: 'INIT',
  IFRAME: 'IFRAME',
  APP: 'APP',
};

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  providers: [MessageService, Toast],
})
export class AppLayoutComponent {
  readonly env = environment;
  smallScreen = 1024;
  isSmallScreen: boolean = false;
  sidebarVisible: boolean = false;

  user: User;
  stateView: string = STATE_RENDER_APP.INIT;
  sanitizer = inject(DomSanitizer);
  iframeUrl: SafeResourceUrl;
  currency: string = '';

  constructor(
    private guidelineService: GuidelineService,
    private httpClient: HttpClient,
    private translator: CustomTranslateService,
    private billingService: BillingService
  ) {
    this.guidelineService.onInit();
    window.addEventListener('message', this.handleReceiveMessage);
  }

  ngOnInit() {
    this.checkSmallScreen();
    window.addEventListener('resize', () => this.checkSmallScreen());

    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.IFRAME_CONNECT_URL
    );
  }

  getDataBeforeInitApp() {
    const authData = JSON.parse(localStorage.getItem('auth') as string) || '';
    if (!authData) return;
    const sources: Observable<any>[] = [
      this.httpClient.get(
        `${environment.BE_URL}user/users/${authData?.id}?private_privacy=false`
      ),
      this.httpClient.get(`${environment.BE_URL}restaurant/restaurants/info`),
    ];
    forkJoin(sources)
      .pipe(
        catchError(() => {
          window.location.href = `${environment.FOOD_URL}`;
          return of();
        }),
        switchMap((results) => {
          const [userRes, restaurantRes] = results;
          if (!userRes?.data || !restaurantRes?.data) {
            window.location.href = `${environment.FOOD_URL}`;
          }
          if (userRes) {
            const language = (userRes?.data?.language || 'en').toLowerCase();
            registerLocaleData(localeVi, language);
            this.translator.setLanguage(language);
          }

          this.currency = restaurantRes?.data?.currency?.toUpperCase();

          return this.billingService.getConversionCurrency(
            this.currency || 'USD'
          );
        })
      )
      .subscribe({
        next: (res) => {
          const expiry = new Date().getTime() + 1 * 60 * 60 * 1000;
          const rate = res.data || 1;
          localStorage.setItem(
            'currency-conversation-data',
            JSON.stringify({
              currency: this.currency,
              rate: rate,
              expiry: expiry,
              hash: this.currency + rate + expiry,
            })
          );
        },
        error: () => {
          window.location.href = `${environment.LOGIN_URL}/login?r=${environment.FE_URL}`;
        },
        complete: () => {
          this.stateView = STATE_RENDER_APP.APP;
        },
      });
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  handleOnLoad = () => {
    const frame = document.getElementById('socialLogin');
    if (this.isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage({ type: 'connect' }, '*');
    }
  };

  handleReceiveMessage = (e: any) => {
    if (e?.data?.type === 'authorized') {
      const accessTokenData = e.data?.token?.accessToken;
      const profileData = JSON.parse(e.data?.profile);
      if (accessTokenData && profileData) {
        localStorage.setItem('accessToken', accessTokenData);
        localStorage.setItem('auth', JSON.stringify(profileData));
        this.user = {
          avatarURL: profileData?.avatar_thumbnail_url,
          full_name: profileData?.full_name,
        };
        this.getDataBeforeInitApp();
      } else {
        window.location.href = `${environment.LOGIN_URL}/login?r=${environment.FE_URL}`;
      }
    }
  };

  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.smallScreen;
  }
}
