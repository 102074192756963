import { MenuSidebar } from 'src/app/core/constants/navigations.config';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, PrimeIcons } from 'primeng/api';
import * as _ from 'lodash';
import { RestaurantControllerService } from '@soctrip/angular-restaurant-service';

import {
  TabGuidelineService,
  stepGuidelineConfig,
} from 'src/app/features/guideline/services/tab-guideline.service';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';

import { TabGuidelineEnumId } from 'src/app/features/guideline/enum/tab-id.enum';
import { SeverityLevel } from 'src/app/core/constants/severity.enum';
import { NavigationItem } from 'src/app/core/models/interfaces/navigation-item';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  dialItems: any[];
  MenuSidebar = MenuSidebar;
  isMenuShow: boolean = true;
  showChildren: boolean[] = [];

  @Input() sidebarVisible = false;
  @Output() sidebarVisibleChange = new EventEmitter<boolean>();
  protected readonly SoctripIcons = SoctripIcons;

  constructor(
    private tabGuidelineService: TabGuidelineService,
    private router: Router,
    private shopService: RestaurantControllerService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.setupSideBar();
    this.showChildren = this.MenuSidebar.map((item) => !!item.children);
    this.tabGuidelineService.step$.subscribe((step) => {
      this.tabGuidelineService.setStep(step);
    });
    this.tabGuidelineService.finish$.subscribe((finish) => {
      if (finish) {
        this.messageService.add({
          severity: SeverityLevel.SUCCESS,
          detail: 'Complete instructions',
          icon: PrimeIcons.CHECK_CIRCLE,
        });
      }
    });
  }

  ngAfterViewInit() {
    this.shopService.restaurantsStatusGet().subscribe({
      next: (res) => {
        this.tabGuidelineService.onInit(
          res?.data?.stage ?? stepGuidelineConfig.length, res?.data?.code ?? ""
        );
        const step = res?.data?.stage ?? stepGuidelineConfig.length;
        this.tabGuidelineService.stepSubject.next(step);
      },
      error: () => {
        this.tabGuidelineService.onInit(stepGuidelineConfig.length, "");
        this.tabGuidelineService.stepSubject.next(stepGuidelineConfig.length);
      },
    });
  }

  setupSideBar() {
    const toggleItem = {
      icon: 'sctr-icon-chevron-right',
      command: () => {
        this.isMenuShow = !this.isMenuShow;
      },
    };
    this.dialItems = _.cloneDeep(MenuSidebar);
    this.dialItems.unshift(toggleItem);
    this.dialItems.forEach((item) => {
      item.routerLink = [item.path];
      item.icon += ' before:!text-white';
    });
  }

  isActive(path: string, isParent?: boolean) {
    const locationPath = window.location.pathname;
    if (isParent) {
      return locationPath.slice(1) === path;
    } else {
      const index = locationPath.search(path);
      return (
        index > 0 &&
        locationPath[index - 1] === '/' &&
        locationPath.includes(path)
      );
    }
  }

  isDisableLink(id?: TabGuidelineEnumId) {
    if (!id) return;
    return this.tabGuidelineService.isActiveStep(id);
  }

  getMenuIcon() {
    return this.isMenuShow ? 'pi pi-chevron-left' : 'pi pi-chevron-right';
  }

  updateSideBar(value: boolean = this.sidebarVisible) {
    this.sidebarVisible = value;
    this.sidebarVisibleChange.emit(this.sidebarVisible);
  }

  onNavigate(id?: TabGuidelineEnumId, path?: string) {
    if (!path || this.isDisableLink(id)) return;
    this.router.navigateByUrl(path);
    this.updateSideBar(false);
  }

  onClickItemSidebar(item: NavigationItem, index: number) {
    if (item.children) {
      if (!this.isMenuShow) {
        this.isMenuShow = true;
        this.showChildren[index] = true;
        return;
      }
      this.showChildren[index] = !this.showChildren[index];
    } else {
      this.onNavigate(item.id, item.path);
    }
  }
}
