import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RestaurantControllerService } from '@soctrip/angular-restaurant-service';
import { AppService } from 'src/app/core/services/app.service';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { environment } from 'src/environments/environment';

export interface User {
  avatarURL: string;
  full_name: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  readonly env = environment;

  @Input() isSmallScreen: boolean = false;
  @Input() user: User;
  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter();

  url = '';
  isRestaurantPublic = true;
  status: 'DENIED' | 'NEW' | 'ACCEPTED';
  isSuspension = false;

  translator = inject(CustomTranslateService);
  restaurantService = inject(RestaurantControllerService);
  router = inject(Router);
  appService = inject(AppService);

  foodURL = environment.FOOD_URL;

  ngOnInit(): void {
    this.restaurantService.restaurantsInfoGet().subscribe({
      next: (res) => {
        this.isRestaurantPublic = res?.data?.is_market_public ?? false;
        this.isSuspension = res?.data?._suspension_mode;
        this.status = res?.data?.accepted_status;
      },
    });

    this.url = this.router.url;
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
        }
      },
    });

    this.appService.getIsSuspension().subscribe({
      next: (res) => {
        this.isSuspension = res;
      },
    });
  }

  changeLanguage(language: string) {
    this.translator.setLanguage(language);
  }
}
